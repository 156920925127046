<template>
  <div class="app-image">
    <img
      v-if="thumbnail"
      loading="lazy"
      class="app-image__image"
      referrerPolicy="no-referrer"
      :src="thumbnail"
    >
    <div v-else class="app-image__error">
      <icon class="app-image__error-icon" name="streamline:entertainment-control-button-play-circle-controls-media-multi-play-multimedia-button-circle" />
      <span class="app-image__error-text">{{ $t('image_error') }}</span>
    </div>
    <div v-if="!sortMode && isVideo && thumbnail" class="app-image__video-overlay">
      <icon class="app-image__video-overlay-icon" name="streamline:entertainment-control-button-play-circle-controls-media-multi-play-multimedia-button-circle" />
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue'

  const props = defineProps({
    image: {
      type: Object,
      required: true,
    },
    sortMode: {
      type: Boolean,
      default: false,
    },
  })

  const thumbnail = computed(() => props.image.thumbFilePath)

  const isVideo = computed(() => props.image?.mimeType?.match('video.*'))
</script>

<style lang="scss">
  .app-image {
    display: flex;
    content-visibility: auto;
    position: relative;
  }

  .app-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .app-image__error {
    background: var(--color-light-gray);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: var(--spacing-default) 0;
  }

  .app-image__error-icon {
    font-size: 2rem;
    margin-bottom: var(--spacing-default);
    color: var(--color-primary);
  }

  .app-image__video-overlay {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app-image__video-overlay-icon {
    font-size: 4rem;
    color: #fff;
    opacity: 0.6;
    transition: all .2s ease-in-out;
  }

  .app-image__video-overlay:hover .app-image__video-overlay-icon {
    opacity: 1;
  }
</style>
